import { Avatar, AvatarGroup, Card, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";
import FileUpload from "components/FileUpload";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiModal from "components/SuiModal";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import Footer from "examples/Footer";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useFormState } from "helpers/hooks/form.hook";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { getDashboardMeta } from "redux/actions/dashboard.action";
import { getUser } from "redux/actions/users.action";
import { LogOutAuthAction } from "../../redux/actions/auth.action";
import ErrorHandler from "../auth/error/ErrorHandler";
import CheckIcon from "@mui/icons-material/Check";
import { updateChannel } from "redux/actions/channel.action";
import { setChannel } from "redux/actions/channel.action";

function Dashboard(props) {
  const { errorHandler, getMeta, meta } = props;

  const [formState, handleInputChange, resetFormState] = useFormState({});
  const [selectedChannel, setSelectedChannel] = useState({});

  useEffect(() => {
    Promise.all([getMeta()]);
  }, []);

  const renderUsers = () => {
    const { users = [] } = props?.channel || {};
    return users.reduce((acc, current) => {
      const _user = props.users.find((usr) => usr._id === current);
      if (_user) {
        return [
          ...acc,
          <SuiAvatar
            sx={{ width: 60, height: 60 }}
            alt={`${_user.firstname}${" " + _user.lastname}`}
            src={_user.avatar}
          />,
        ];
      }
      return acc;
    }, []);
  };

  return (
    <DashboardLayout>
      <ErrorHandler errorHandler={errorHandler || { hasError: false, message: "" }} />
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={6}>
              <Card sx={{ height: "100%" }}>
                <SuiBox sx={{ height: "100%" }}>
                  <SuiBox sx={{ height: "100%" }} p={2} pt={2} backgroundColor={"info"}>
                    <SuiTypography
                      pt={1}
                      pl={2}
                      pb={1}
                      textColor={"white"}
                      fontWeight="light"
                      opacity={1}
                      fontSize="2rem"
                    >
                      Hi,
                    </SuiTypography>
                    <Grid container alignItems="center">
                      <Grid item xs={12} lg={3}>
                        <SuiAvatar
                          size="xxl"
                          sx={{ width: 100, height: 100 }}
                          src={props?.channel?.channel_avatar}
                        ></SuiAvatar>
                      </Grid>

                      <Grid item xs={8} lg={9}>
                        <SuiBox ml={1}>
                          <SuiTypography variant="h2" fontWeight="light" textColor={"white"}>
                            {props?.channel?.name}
                          </SuiTypography>
                        </SuiBox>
                      </Grid>
                    </Grid>
                  </SuiBox>
                </SuiBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} xl={6}>
              <NavLink to="/content">
                <MiniStatisticsCard
                  title={{ text: "Content" }}
                  count={meta?.content_count || 0}
                  // percentage={{ color: "success", text: "+3%" }}
                  icon={{ color: "info", component: "video_library" }}
                />
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <NavLink to="/series">
                <MiniStatisticsCard
                  title={{ text: "Series" }}
                  count={meta?.series_count || 0}
                  // percentage={{ color: "error", text: "-2%" }}
                  icon={{ color: "info", component: "watch_later" }}
                />
              </NavLink>
            </Grid>
            <Grid item xs={12} sm={6} xl={6}>
              <NavLink to="/category">
                <MiniStatisticsCard
                  title={{ text: "Category", fontWeight: "bold" }}
                  count={meta?.category_count || 0}
                  // percentage={{ color: "success", text: "+55%" }}
                  icon={{ color: "info", component: "live_tv" }}
                />
              </NavLink>
            </Grid>
            {/* <Grid item xs={12} sm={6} xl={6}>
              <NavLink to="/ads">
                <MiniStatisticsCard
                  title={{ text: "Ads", fontWeight: "bold" }}
                  count={meta?.ad_count || 0}
                  icon={{ color: "info", component: "video_library" }}
                />
              </NavLink>
            </Grid> */}
          </Grid>
        </SuiBox>
      </SuiBox>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.authState,
    meta: state.dashboard.meta,
    channel: state.channel.current,
    users: state?.users?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (history) => {
      dispatch(LogOutAuthAction(history));
    },
    getMeta: () => {
      dispatch(getDashboardMeta());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
