import { ad } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";
import { AuthActionType } from "./auth.action";

export const AdActionTypes = {
  CREATE_AD: "CREATE_AD",
  DELETE_AD: "DELETE_AD",
  UPDATE_AD: "UPDATE_AD",
  GET_AD: "GET_AD",
  SET_AD: "SET_AD",
};

export const getAds = () => {
  return (dispatch) =>
    axios
      .get(ad)
      .then((res) => {
        const { data } = res;
        dispatch({ type: AdActionTypes.GET_AD, payload: data });
        return res;
      })
      .catch((err) => {
        const error = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Ads Error..").error(error);

        // on 403 remove auth token
        if (error && err?.response?.status == 403) {
          dispatch({ type: AuthActionType.LOGOUT_SUCCESS });
        }

        throw err;
      });
};

export const createAd = (payload) => {
  return (dispatch) =>
    axios
      .post(ad, payload)
      .then((res) => getAds()(dispatch))
      .catch((err) => {
        const error = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Ads Error..").error(error);

        throw err;
      });
};

export const updateAd = (payload) => {
  return (dispatch) =>
    axios
      .put(`${ad}/${payload._id}`, payload)
      .then((res) => {
        getAds()(dispatch).then(() => {
          dispatch({ type: AdActionTypes.SET_AD, payload: payload._id });
        });
      })
      .catch((err) => {
        const error = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Ads Error..").error(error);
        throw err;
      });
};

export const deleteAd = (id) => {
  return (dispatch) =>
    axios
      .delete(`${ad}/${id}`)
      .then((res) => {
        getAds()(dispatch);
        dispatch({ type: AdActionTypes.SET_AD, payload: null });
      })
      .catch((err) => {
        const error = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Ads Error..").error(error);
        throw err;
      });
};

export const setAd = (id) => (dispatch) => {
  dispatch({ type: AdActionTypes.SET_AD, payload: id });
};
