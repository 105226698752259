import axios from "axios";
import { AuthActionType } from "redux/actions/auth.action";
import { DASHBOARD_ACTION_TYPES } from "redux/actions/dashboard.action";

const dashboardState = {
  meta: {},
};

const dashboardreducer = (state = dashboardState, action) => {
  switch (action.type) {
    case DASHBOARD_ACTION_TYPES.SET_META: {
      return { meta: action.payload };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return dashboardState;
    }

    default:
      return state;
  }
};

export default dashboardreducer;
