// Soft UI Dashboard React components
import { Badge, Icon } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

// { image, name, description }
function Title({ image, name, description }) {
  return (
    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <SuiBox mr={2}>
        <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
      </SuiBox>
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="button" fontWeight="medium">
          {name}
        </SuiTypography>
        <SuiBox sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "10rem" }}>
          <SuiTypography variant="caption" fontWeight="secondary">
            {description}
          </SuiTypography>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

export default {
  columns: [
    { name: "title", align: "left" },
    { name: "category(s)", align: "left" },
    { name: "video_url", align: "left" },
    { name: "status", align: "cente" },
    // { name: "is_premium", align: "center" },
    // { name: "updated_at", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: (list, onEdit, onDelete) =>
    list.map((item) => ({
      title: <Title image={item?.thumbnail} name={item.title} description={item.description} />,
      video_url: (
        <SuiBox sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "30rem" }}>
          <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
            {item.video_url}
          </SuiTypography>
        </SuiBox>
      ),
      status: (
        <SuiBadge
          variant="gradient"
          badgeContent={item?.status}
          color={item?.status === "active" ? "success" : "error"}
          size="extra-small"
        />
      ),
      "category(s)": (
        <Badge badgeContent={item?.category?.length || "0"} color="success">
          <SuiBadge
            variant="gradient"
            badgeContent={"Categories"}
            color={"warning"}
            size="extra-small"
          />
        </Badge>
      ),
      // is_premium: (
      //   <SuiBadge
      //     variant="gradient"
      //     badgeContent={item?.is_premium ? "Premium" : "Free"}
      //     color={item?.is_premium ? "success" : "error"}
      //     size="extra-small"
      //   />
      //   // <SuiButton variant="text" buttonColor="dark">
      //   //   <Icon className="material-icons-round">{item?.is_premium ? "checked" : "cancel"}"</Icon>
      //   // </SuiButton>
      // ),

      updated_at: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.updatedAt ? new Date(item.updatedAt).toLocaleString() : ""}
        </SuiTypography>
      ),
      action: (
        <SuiBox>
          <SuiButton variant="text" buttonColor="dark" onClick={onEdit(item)}>
            <Icon className="material-icons-round">edit</Icon>&nbsp;edit
          </SuiButton>
          <SuiButton variant="text" buttonColor="dark" onClick={onDelete(item)}>
            <Icon className="material-icons-round">delete</Icon>&nbsp;delete
          </SuiButton>
        </SuiBox>
      ),
    })),
};
