import React from "react";
import PropTypes from "prop-types";

import SuiTypography from "components/SuiTypography";
import Switch from "@mui/material/Switch";

function SuiToggle(props) {
  const { label, value, onChange, name } = props;

  const handleToggleChange = (obj) => {
    onChange({ [name]: obj?.target?.checked || false });
  };
  return (
    <div>
      <SuiTypography variant="overline" textColor="white">{label}</SuiTypography>
      <br />
      <Switch name={name} checked={value} onChange={handleToggleChange} />
    </div>
  );
}

SuiToggle.propTypes = {};

export default SuiToggle;
