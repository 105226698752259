import axios from "axios";
import { AuthActionType } from "redux/actions/auth.action";
import { CategoryActionTypes } from "redux/actions/category.action";

const categoryState = {
  list: [],
};

const categoryReducer = (state = categoryState, action) => {
  switch (action.type) {
    case CategoryActionTypes.GET_CATEGORY: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return categoryState;
    }
    default:
      return state;
  }
};

export default categoryReducer;
