import { content } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const ContentActionTypes = {
  CREATE_CONTENT: "CREATE_CONTENT",
  DELETE_CONTENT: "DELETE_CONTENT",
  UPDATE_CONTENT: "UPDATE_CONTENT",
  GET_CONTENT: "GET_CONTENT",
};

export const getContent = (channel_id) => {
  return (dispatch, getState) => {
    dispatch({ type: ContentActionTypes.GET_CONTENT, payload: [] });
    return axios
      .get(`${content}/${channel_id}`, {})
      .then((res) => {
        const { data } = res;
        dispatch({ type: ContentActionTypes.GET_CONTENT, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Content Error..").error(_err);
        throw err;
      });
  };
};

export const createContent = (payload) => {
  return (dispatch, getState) => {
    return axios
      .post(content, { ...payload })
      .then((res) => getContent(payload?.channel_id)(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Content Error..").error(_err);
        throw err;
      });
  };
};

export const updateContent = (payload) => {
  return (dispatch, getState) =>
    axios
      .put(`${content}/${payload._id}`, payload)
      .then((res) => getContent(payload?.channel_id)(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Content Error..").error(_err);
        throw err;
      });
};

export const deleteContent = (id) => {
  return (dispatch, getState) =>
    axios
      .delete(`${content}/${id}`)
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Content Error..").error(_err);
        throw err;
      });
};
