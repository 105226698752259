import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import authreducer from "./reducers/auth.reducer";
import thunk from "redux-thunk";
import authErrorReducer from "./reducers/auth.errorreducer";
import dashboardReducer from "./reducers/dashbobard.reducer";
import contentReducer from "./reducers/content.reducer";
import channelReducer from "./reducers/channel.reducer";
import liveStreamReducer from "./reducers/live_stream.reducer";
import scheduledContentReducer from "./reducers/scheduled_content.reducer";
import playlistReducer from "./reducers/playlist.reducer";
import userReducer from "./reducers/user.reducer";
import categoryReducer from "./reducers/category.reducer";
import seriesReducer from "./reducers/series.reducer";
import seasonReducer from "./reducers/season.reducer";
import episodeReducer from "./reducers/episode.reducer";
import adReducer from "./reducers/ad.reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  authState: authreducer,
  authError: authErrorReducer,
  dashboard: dashboardReducer,
  content: contentReducer,
  liveStream: liveStreamReducer,
  scheduledContent: scheduledContentReducer,
  channel: channelReducer,
  playlist: playlistReducer,
  users: userReducer,
  category: categoryReducer,
  series: seriesReducer,
  season: seasonReducer,
  episode: episodeReducer,
  ad: adReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
export { store };
