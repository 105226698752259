import { useState } from "react";

export const useFormState = (initialState) => {
  const [formState, setFormState] = useState(initialState);

  const handleInputChange = (valObj) => {
    setFormState({ ...formState, ...valObj });
  };
  const resetState = (reset = {}) => setFormState(reset);

  return [formState, handleInputChange, resetState];
};
