import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiModal from "components/SuiModal";
import SuiToggle from "components/SuiToggle";
import { useFormState } from "helpers/hooks/form.hook";
import { Grid } from "@mui/material";
// Soft UI Dashboard React example components
import styles from "layouts/tables/styles";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import adContent from "layouts/tables/data/adContent";
import Footer from "examples/Footer";
import Table from "examples/Table";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import "./index.scss";
import { createAd, getAds, setAd, deleteAd, updateAd } from "redux/actions/ad.action";

function AdsComponent(props) {
  const classes = styles();
  const { ads, channel } = props;
  const { columns, rows } = adContent;
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [formState, handleInputChange, resetFormState] = useFormState({
    pre_roll: false,
    mid_roll: false,
    post_roll: false,
    ad_positions: "",
    ad_urls: "",
  });
  const [selectedAd, setSelectedAd] = useState({});

  const handleClose = (func) => () => {
    func(false);
    setSelectedAd({});
    resetFormState();
  };

  const handleShow = (func) => () => {
    func(true);
  };

  useEffect(() => {
    props.get();
  }, []);

  useEffect(() => {
    if (selectedAd) {
      resetFormState({
        ...selectedAd,
        ad_positions: selectedAd?.ad_positions?.join(","),
        ad_urls: selectedAd?.ad_urls?.join(","),
      });
    }
  }, [selectedAd]);

  const handleDelete = () => {
    props.delete(selectedAd._id).then(() => {
      handleClose(setShowDelete)();
      props.get();
    });
  };
  const renderAdCard = () => {
    if (ads?.length < 1) {
      return (
        <Grid xs={12} md={6} xl={3} style={{ padding: "30px" }}>
          No Ads Found !!
        </Grid>
      );
    }
    return (
      <SuiBox customClass={classes.tables_table} style={{ width: "100%" }}>
        <Table
          columns={columns}
          rows={rows(
            ads,
            (adData) => () => {
              // resetFormState(adData);
              setSelectedAd(adData);
              handleShow(setShow)();
            },
            (adData) => () => {
              // resetFormState(adData);
              setSelectedAd(adData);
              handleShow(setShowDelete)();
            }
          )}
        />
      </SuiBox>
    );
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Ads</SuiTypography>
              <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShow)}>
                Add Ad
              </SuiButton>
            </SuiBox>
            <SuiBox p={2}>
              <Grid container spacing={3}>
                {renderAdCard()}
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>

      <>
        <SuiModal
          header={`${selectedAd?._id ? "Update" : "Create"} Ad`}
          open={show}
          dismissible={true}
          sx={{
            minWidth: "40rem !important",
            width: "40rem",
          }}
          closeModal={handleClose(setShow)}
        >
          <SuiBox as="form" display="flex" flexDirection="column" flex={1}>
            <SuiBox mb={2}>
              <SuiInput
                label="Ad Url (add , separation for multiple value)"
                name="ad_urls"
                value={formState.ad_urls || ""}
                placeholder="Ad Url"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox flex={2}>
              <SuiBox flex={2} style={{ display: "inline-block", marginLeft: "20px" }}>
                <SuiToggle
                  label="Pre-roll"
                  name="pre_roll"
                  value={formState.pre_roll}
                  onChange={handleInputChange}
                />
              </SuiBox>
              <SuiBox flex={2} style={{ display: "inline-block", marginLeft: "20px" }}>
                <SuiToggle
                  label="Mid-roll"
                  name="mid_roll"
                  value={formState.mid_roll}
                  onChange={handleInputChange}
                />
              </SuiBox>
              <SuiBox flex={2} style={{ display: "inline-block", marginLeft: "20px" }}>
                <SuiToggle
                  label="Post-roll"
                  name="post_roll"
                  value={formState.post_roll}
                  onChange={handleInputChange}
                />
              </SuiBox>
            </SuiBox>
            {formState.mid_roll ? (
              <SuiBox mb={2}>
                <SuiInput
                  label="Mid-roll positions (add , separation for multiple value)"
                  name="ad_positions"
                  value={formState.ad_positions || ""}
                  placeholder="Enter Mid-roll Ad Position"
                  onChange={handleInputChange}
                />
              </SuiBox>
            ) : null}

            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="button"
                  buttonColor="black"
                  onClick={(e) => {
                    e.preventDefault();
                    const funcToExec = selectedAd?._id ? props.update : props.create;
                    funcToExec({
                      ...formState,
                      ad_urls: formState?.ad_urls?.split(",") || [],
                      ad_positions: formState?.ad_positions?.split(",") || [],
                      pre_roll: formState.pre_roll || false,
                      mid_roll: formState.mid_roll || false,
                      post_roll: formState.post_roll || false,
                      brand: channel?._id,
                    }).then(() => {
                      setShow(false);
                      resetFormState({});
                    });
                  }}
                >
                  {selectedAd?._id ? "Update" : "Create"}
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShow)}>
                  Cancel
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <>
        <SuiModal header="Delete Ad" open={showDelete} closeModal={handleClose(setShowDelete)}>
          <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2">Are you sure, you want to delete ?</SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="submit"
                  // variant="gradient"
                  buttonColor="black"
                  onClick={handleDelete}
                >
                  Yes
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton
                  type="reset"
                  // variant="contained"
                  buttonColor="black"
                  onClick={handleClose(setShowDelete)}
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state?.authState?.user || {},
    channel: state.channel.current || {},
    ads: state?.ad?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createAd(payload)),
    get: () => dispatch(getAds()),
    getUsers: () => dispatch(getUser()),
    delete: (id) => dispatch(deleteAd(id)),
    update: (payload) => dispatch(updateAd(payload)),
    set: (payload) => dispatch(setAd(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsComponent);
