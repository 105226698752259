import { playlist } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const PlaylistActionTypes = {
  CREATE_PLAYLIST: "CREATE_PLAYLIST",
  DELETE_PLAYLIST: "DELETE_PLAYLIST",
  UPDATE_PLAYLIST: "UPDATE_PLAYLIST",
  GET_PLAYLIST: "GET_PLAYLIST",
};

export const getPlaylist = () => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id: channel_id } = current;
    dispatch({ type: PlaylistActionTypes.GET_PLAYLIST, payload: [] });
    return axios
      .get(playlist, {
        params: {
          channel_id,
        },
      })
      .then((res) => {
        const { data } = res;
        dispatch({ type: PlaylistActionTypes.GET_PLAYLIST, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Playlist Error..").error(_err);
        throw err;
      });
  };
};

export const createPlaylist = (payload) => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id: channel_id } = current;

    return axios
      .post(playlist, { ...payload, channel_id })
      .then((res) => getPlaylist()(dispatch,getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Playlist Error..").error(_err);
        throw err;
      });
  };
};

export const updatePlaylist = (payload) => {
  return (dispatch,getState) =>
    axios
      .put(`${playlist}/${payload._id}`, payload)
      .then((res) => getPlaylist()(dispatch,getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Playlist Error..").error(_err);
        throw err;
      });
};

export const deletePlaylist = (id) => {
  return (dispatch,getState) =>
    axios
      .delete(`${playlist}/${id}`)
      .then((res) => getPlaylist()(dispatch,getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Playlist Error..").error(_err);
        throw err;
      });
};
