import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiModal from "components/SuiModal";
import { useFormState } from "helpers/hooks/form.hook";
import { Grid } from "@mui/material";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SeriesCard from "examples/Cards/ProjectCards/SeriesCard";
import Footer from "examples/Footer";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";

import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
// Custom styles for the Tables
import FileUpload from "components/FileUpload";
import { ListItemText, MenuItem } from "@mui/material";

import "./playlist.scss";

import {
  getEpisode,
  createEpisode,
  deleteEpisode,
  updateEpisode,
  setEpisode,
} from "redux/actions/episode.action";

function EpisodeComponent(props) {
  const history = useHistory();
  const params = useParams();
  const { episodes } = props;
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [formState, handleInputChange, resetFormState] = useFormState({});
  const [selectedEpisode, setSelectedEpisode] = useState({});

  const handleClose = (func) => () => {
    func(false);
    setSelectedEpisode({});
    resetFormState();
  };

  const handleShow = (func) => () => {
    func(true);
  };

  useEffect(() => {
    props.get(params?.series_id, params?.season_id);
  }, []);

  useEffect(() => {
    resetFormState(selectedEpisode);
  }, [selectedEpisode]);

  const handleDelete = () => {
    props.delete(params.series_id, params.season_id, selectedEpisode._id).then(() => {
      handleClose(setShowDelete)();
      props.get(params.series_id, params.season_id);
    });
  };

  const renderChannelCard = () => {
    if (episodes?.length < 1) {
      return (
        <Grid xs={12} md={6} xl={3} style={{ padding: "30px" }}>
          No Episode Found !!
        </Grid>
      );
    }
    return (episodes || []).map((item, index) => (
      <Grid xs={12} md={6} xl={3} mb={3} style={{ padding: "20px" }}>
        <SeriesCard
          image={item.thumbnail}
          title={item.title}
          description={item?.description}
          onDeleteClick={() => {
            setSelectedEpisode(item);
            setShowDelete(true);
          }}
          onEditClick={() => {
            setSelectedEpisode(item);
            resetFormState(item);
            setShow(true);
          }}
        />
      </Grid>
    ));
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">
                Series &gt; {params.series_path} &gt; {params.season_path}
              </SuiTypography>
              <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShow)}>
                Add Episode
              </SuiButton>
            </SuiBox>
            <SuiBox p={2}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={9} xl={3}>
                  <PlaceholderCard
                    title={{ variant: "h5", text: "New Series" }}
                    outlined
                    // onClick={handleShow(setShowModal)}
                  />
                </Grid> */}
                {renderChannelCard()}
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>

      <>
        <SuiModal
          header={`${selectedEpisode?._id ? "Update" : "Create"} Series`}
          open={show}
          dismissible={true}
          sx={{
            minWidth: "40rem !important",
            width: "40rem",
          }}
          closeModal={handleClose(setShow)}
        >
          <SuiBox as="form" display="flex" flexDirection="column" flex={1}>
            <SuiBox mb={2}>
              <SuiInput
                label="Title"
                name="title"
                value={formState.title || ""}
                placeholder="Enter title"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Description"
                name="description"
                value={formState.description || ""}
                placeholder="Enter description"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Position"
                name="position"
                value={formState.position || ""}
                placeholder="Enter Position"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiSelect
                label="Status"
                onChange={handleInputChange}
                name="status"
                value={formState.status}
                options={[
                  { value: "Active", label: "Active" },
                  { value: "Inactive", label: "Inactive" },
                ]}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Cast"
                name="cast"
                value={formState.cast || ""}
                placeholder="Enter cast"
                onChange={handleInputChange}
              />
            </SuiBox>
            {/* <SuiBox mb={2}>
              <SuiSelect
                label="Category"
                onChange={handleInputChange}
                name="category"
                value={formState.category}
                options={props.category?.map((item) => {
                  return { value: item?.name, label: item?.name };
                })}
              >
                {props.category?.map((op) => {
                  return (
                    <MenuItem key={op._id} value={op.name}>
                      <ListItemText fontSize="small" secondary={op.name} />
                    </MenuItem>
                  );
                })}
              </SuiSelect>
            </SuiBox> */}
            <SuiBox mb={3}>
              <FileUpload
                name="thumbnail"
                label="Episode Thumbnail"
                url={formState.thumbnail}
                onChange={handleInputChange}
              ></FileUpload>
            </SuiBox>

            <SuiBox mb={3}>
              <FileUpload
                name="video_url"
                label="Episode Video"
                url={formState.video_url}
                onChange={handleInputChange}
              ></FileUpload>
            </SuiBox>

            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="button"
                  buttonColor="black"
                  onClick={(e) => {
                    e.preventDefault();
                    const funcToExec = selectedEpisode?._id ? props.update : props.create;
                    funcToExec({
                      ...formState,
                      cast: formState.cast?.split(","),
                      series_id: params?.series_id,
                      season_id: params?.season_id,
                    }).then(() => {
                      handleClose(setShow);
                      resetFormState();
                    });
                  }}
                >
                  {selectedEpisode?._id ? "Update" : "Create"}
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShow)}>
                  Cancel
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <>
        <SuiModal header="Delete Series" open={showDelete} closeModal={handleClose(setShowDelete)}>
          <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2">
                Are you sure, you want to delete <strong>{selectedEpisode.name}</strong> ?
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="submit"
                  // variant="gradient"
                  buttonColor="black"
                  onClick={handleDelete}
                >
                  Yes
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton
                  type="reset"
                  // variant="gradient"
                  buttonColor="black"
                  onClick={handleClose(setShowDelete)}
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    episodes: state?.episode?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createEpisode(payload)),
    get: (series_id, season_id) => dispatch(getEpisode(series_id, season_id)),
    delete: (series_id, season_id, episode_id) =>
      dispatch(deleteEpisode(series_id, season_id, episode_id)),
    update: (payload) => dispatch(updateEpisode(payload)),
    set: (payload) => dispatch(setEpisode(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EpisodeComponent);
