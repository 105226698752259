/**
=========================================================
* Soft UI Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import SuiAvatar from "components/SuiAvatar";

// Custom styles for the DefaultProjectCard
import styles from "examples/Cards/ProjectCards/DefaultProjectCard/styles";
import { Avatar, AvatarGroup, Icon } from "@mui/material";

function SeriesCard({ image, title, description, action, onEditClick, onDeleteClick }) {
  const classes = styles();
  return (
    <Card className={classes.projectCard}>
      <SuiBox customClass={classes.projectCard_imageContainer}>
        <CardMedia
          src={image}
          component="img"
          title={title}
          className={classes.projectCard_image}
          sx={{ minHeight: "12rem" }}
        />
      </SuiBox>
      <SuiTypography
        component="text"
        variant="body2"
        textColor={"white"}
        // opacity={light ? 0.8 : 0.5}
        customClass="line-height-0"
        style={{
          height: "40px",
          color: "#000",
          display: "flex",
          alignItems: "center",
          paddingLeft: "10px",
        }}
      >
        {title}
      </SuiTypography>
      <SuiBox pt={3} px={0.5}>
        <SuiBox display="flex" justifyContent="space-between" alignItems="center">
          {action?.type === "internal" ? (
            <SuiButton
              component={Link}
              to={action?.route}
              variant="outlined"
              size="small"
              buttonColor={action.color}
            >
              {action.label}
            </SuiButton>
          ) : action ? (
            <SuiButton
              variant="outlined"
              size="small"
              buttonColor={action?.color}
              onClick={action?.onClick}
              sx={{ flex: 1 }}
              style={{ marginRight: "1rem" }}
            >
              {action.label}
            </SuiButton>
          ) : null}
          <SuiBox display="flex">
            <SuiButton size="small" onClick={onEditClick} style={{ marginRight: "1rem" }}>
              <Icon>edit</Icon>
            </SuiButton>
            <SuiButton size="small" onClick={onDeleteClick}>
              <Icon>delete</Icon>
            </SuiButton>
          </SuiBox>
        </SuiBox>
      </SuiBox>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
SeriesCard.defaultProps = {
  series: [],
};

// Typechecking props for the DefaultProjectCard
SeriesCard.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default SeriesCard;
