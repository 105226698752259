import { AuthActionType } from "redux/actions/auth.action";
import { PlaylistActionTypes } from "redux/actions/playlist.action";

const playlistState = {
  current: {},
  list: [],
};

const playlistReducer = (state = playlistState, action) => {
  switch (action.type) {
    case PlaylistActionTypes.GET_PLAYLIST: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return playlistState;
    }

    default:
      return state;
  }
};

export default playlistReducer;
