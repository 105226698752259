import { AuthActionType } from "redux/actions/auth.action";
import { EpisodeActionTypes } from "redux/actions/episode.action";

const episodeState = {
  current: {},
  list: [],
};

const episodeReducer = (state = episodeState, action) => {
  switch (action.type) {
    case EpisodeActionTypes.GET_EPISODE: {
      return { ...episodeState, list: [...action.payload] };
    }
    case AuthActionType.LOGIN_SUCCESS: {
      return episodeState;
    }
    default:
      return state;
  }
};

export default episodeReducer;
