import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiModal from "components/SuiModal";
import { useFormState } from "helpers/hooks/form.hook";
import { Grid } from "@mui/material";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SeriesCard from "examples/Cards/ProjectCards/SeriesCard";
import Footer from "examples/Footer";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSelect from "components/SuiSelect";
import { connect } from "react-redux";
import { useHistory } from "react-router";
// Custom styles for the Tables
import FileUpload from "components/FileUpload";
import { ListItemText, MenuItem } from "@mui/material";

import "./index.scss";
import {
  createSeries,
  getSeries,
  setSeries,
  deleteSeries,
  updateSeries,
} from "redux/actions/series.action";
import { getCategory } from "redux/actions/category.action";

function SeriesComponent(props) {
  const history = useHistory();
  const { channel, series, user } = props;
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [formState, handleInputChange, resetFormState] = useFormState({});
  const [selectedSeries, setSelectedSeries] = useState({});

  const handleClose = (func) => () => {
    func(false);
    setSelectedSeries({});
    resetFormState();
  };

  const handleShow = (func) => () => {
    func(true);
  };

  useEffect(() => {
    props.getCategory();
    props.get(channel?._id);
  }, []);

  useEffect(() => {
    resetFormState(selectedSeries);
  }, [selectedSeries]);

  const handleDelete = () => {
    props.delete(selectedSeries._id).then(() => {
      handleClose(setShowDelete)();
      props.get();
    });
  };

  const renderChannelCard = () => {
    if (series?.length < 1) {
      return (
        <Grid xs={12} md={6} xl={3} style={{ padding: "30px" }}>
          No Series Found !!
        </Grid>
      );
    }
    return (series || []).map((item, index) => (
      <Grid xs={12} md={6} xl={3} mb={3} style={{ padding: "20px" }} key={item._id}>
        <SeriesCard
          image={item.thumbnail}
          title={item.title}
          description={item?.description}
          onDeleteClick={() => {
            setSelectedSeries(item);
            setShowDelete(true);
          }}
          onEditClick={() => {
            setSelectedSeries(item);
            resetFormState(item);
            setShow(true);
          }}
          action={{
            color: "info",
            label: "view ",
            onClick: () => {
              // props.set(channel._id);
              history.push(`/series/${item._id}/${item.title}`);
            },
          }}
        />
      </Grid>
    ));
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Series</SuiTypography>
              <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShow)}>
                Add Series
              </SuiButton>
            </SuiBox>
            <SuiBox p={2}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={9} xl={3}>
                  <PlaceholderCard
                    title={{ variant: "h5", text: "New Series" }}
                    outlined
                    // onClick={handleShow(setShowModal)}
                  />
                </Grid> */}
                {renderChannelCard()}
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>

      <>
        <SuiModal
          header={`${selectedSeries?._id ? "Update" : "Create"} Series`}
          open={show}
          dismissible={true}
          sx={{
            minWidth: "40rem !important",
            width: "40rem",
          }}
          closeModal={handleClose(setShow)}
        >
          <SuiBox as="form" display="flex" flexDirection="column" flex={1}>
            <SuiBox mb={2}>
              <SuiInput
                label="Title"
                name="title"
                value={formState.title || ""}
                placeholder="Enter title"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Description"
                name="description"
                value={formState.description || ""}
                placeholder="Enter description"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Position"
                name="position"
                value={formState.position || ""}
                placeholder="Enter Position"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiSelect
                label="Status"
                onChange={handleInputChange}
                name="status"
                value={formState.status}
                options={[
                  { value: "Active", label: "Active" },
                  { value: "Inactive", label: "Inactive" },
                ]}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiSelect
                label="Category"
                onChange={handleInputChange}
                name="category"
                value={formState.category}
                options={props.category?.map((item) => {
                  return { value: item?.name, label: item?.name };
                })}
              >
                {props.category?.map((op) => {
                  return (
                    <MenuItem key={op._id} value={op.name}>
                      <ListItemText fontSize="small" secondary={op.name} />
                    </MenuItem>
                  );
                })}
              </SuiSelect>
            </SuiBox>
            <SuiBox mb={2}>
              <FileUpload
                name="thumbnail"
                label="Series Thumbnail"
                url={formState.thumbnail}
                onChange={handleInputChange}
              ></FileUpload>
            </SuiBox>

            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="button"
                  buttonColor="black"
                  onClick={(e) => {
                    e.preventDefault();
                    const funcToExec = selectedSeries?._id ? props.update : props.create;
                    funcToExec({ ...formState, brand: channel?._id }).then(() => {
                      setShow(false);
                      resetFormState({});
                    });
                  }}
                >
                  {selectedSeries?._id ? "Update" : "Create"}
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShow)}>
                  Cancel
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <>
        <SuiModal header="Delete Series" open={showDelete} closeModal={handleClose(setShowDelete)}>
          <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2">
                Are you sure, you want to delete <strong>{selectedSeries.name}</strong> ?
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="submit"
                  // variant="gradient"
                  buttonColor="black"
                  onClick={handleDelete}
                >
                  Yes
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton
                  type="reset"
                  // variant="contained"
                  buttonColor="black"
                  onClick={handleClose(setShowDelete)}
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state?.authState?.user || {},
    series: state?.series?.list || [],
    users: state?.users?.list || [],
    channel: state.channel.current,
    category: state?.category?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createSeries(payload)),
    get: (id) => dispatch(getSeries(id)),
    getUsers: () => dispatch(getUser()),
    delete: (id) => dispatch(deleteSeries(id)),
    update: (payload) => dispatch(updateSeries(payload)),
    set: (payload) => dispatch(setSeries(payload)),
    getCategory: () => dispatch(getCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeriesComponent);
