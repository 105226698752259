import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import styles from "./fileUpload.module.scss";

import { config } from "aws.config";

import Text from "components/SuiTypography";
import UploadMedia from "redux/services/uploadMedia.service";
import { NotifierInstance } from "utils/notification/notify";
import SuiBox from "components/SuiBox";

function FileUpload(props) {
  const { label, url, onChange, name, accept, labelColor = "white", disabled = false } = props;

  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState("");
  const [type, setType] = useState("img");
  const handleProgress = (percentage) => {
    setProgress(percentage);
  };
  const handleSuccess = (data) => {
    onChange({ [name]: data });
    setFileUrl(data);
    setUploading(false);
  };
  const handleError = (data) => {
    if (data) {
      setUploading(false);
    }
  };

  const mediaUpload = new UploadMedia(handleProgress, handleSuccess, handleError);

  const handleOnChange = (file) => {
    setUploading(true);
    const files = file.target.files;
    const _type = files[0].type;
    if (_type.split("/").indexOf("video") > -1) {
      setType("video");
    }
    mediaUpload
      .setFile(files[0])
      .upload()
      .then((url) => {
        onChange({ [name]: url });
      })
      .catch((err) => {
        NotifierInstance.setTitle("File Upload")
          .setTimeout(3000000)
          .error(
            <Text variant="subtitle1">
              Error uploading media : <Text as="strong">{files[0].name}</Text>
            </Text>
          );
      })
      .finally(() => {});
  };

  const renderPreUploadContent = () => {
    if (uploading)
      return (
        <div
          className={styles.file_upload_progress_bar}
          data-progress={parseInt(progress, 10) + "%"}
        >
          <div style={{ width: `${progress}%` }}></div>
        </div>
      );
    return (
      <React.Fragment>
        <input type="file" onChange={handleOnChange} disabled={disabled} accept={accept} />
        <div>
          <Text variant="body2" textColor={labelColor}>
            Select File
          </Text>
          <Text variant="body2" textColor={labelColor}>
            OR
          </Text>
          <Text variant="body2" textColor={labelColor}>
            Drop Here
          </Text>
        </div>
      </React.Fragment>
    );
  };

  const render = () => {
    if (fileUrl) {
      return (
        <React.Fragment>
          {type === "img" && fileUrl ? (
            <img src={fileUrl} style={{ width: "100%", height: "100%" }} />
          ) : null}
          {type === "video" && fileUrl ? (
            <video style={{ width: "100%", height: "100%" }} src={fileUrl} controls />
          ) : null}
          {!disabled && (
            <div
              title="Remove File"
              onClick={() => {
                onChange({ [name]: null });
                setFileUrl("");
              }}
              className={styles.file_upload_remove_file}
            >
              <span className="icon-close" style={{ fontSize: "12px" }}>
                x
              </span>
            </div>
          )}
        </React.Fragment>
      );
    }
    return renderPreUploadContent();
  };

  useEffect(
    () => () => {
      mediaUpload.cancel();
    },
    []
  );

  return (
    <div
      className={styles.file_upload_widget_wrapper}
      style={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled && "no-drop",
      }}
    >
      {label && (
        <SuiBox>
          <Text variant="overline" textColor={labelColor}>
            {label}
          </Text>
        </SuiBox>
      )}

      <div className={styles.file_upload_wrapper}> {render()}</div>
    </div>
  );
}

FileUpload.propTypes = {};

export default React.memo(FileUpload);
