// import {
//   LOGIN_SUCCESS,
//   LOGIN_FAIL,
//   LOGOUT,
// } from "../actions/types";

// const user = JSON.parse(localStorage.getItem("user"));

// const initialState = user
//   ? { isLoggedIn: true, user }
//   : { isLoggedIn: false, user: null };

// export default function (state = initialState, action) {
//   const { type, payload } = action;

//   switch (type) {
//     case LOGIN_SUCCESS:
//       return {
//         ...state,
//         isLoggedIn: true,
//         user: payload.user,
//       };
//     case LOGIN_FAIL:
//       return {
//         ...state,
//         isLoggedIn: false,
//         user: null,
//       };
//     case LOGOUT:
//       return {
//         ...state,
//         isLoggedIn: false,
//         user: null,
//       };
//     default:
//       return state;
//   }
// }

import axios from "axios";
import { AuthActionType } from "../actions/auth.action";

const authState = {
  isLoggedIn: false,
  user: {
    name: "",
    expiresIn: "",
    token: "",
    authorities: [],
  },
};
const getAuthState = () => {
  const auth = localStorage.getItem("auth");
  try {
    const authobj = JSON.parse(auth);
    const { expiresIn, token } = authobj.user;
    if (new Date(expiresIn) > new Date()) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return authobj;
    }
    return authState;
  } catch (error) {
    return authState;
  }
};
const newAuth = getAuthState();
const authreducer = (state = newAuth, action) => {
  switch (action.type) {
    case AuthActionType.REGISTER_SUCCESS:
      const newAuthState = {
        isLoggedIn: true,
        user: action.payload,
      };
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload.token}`;
      localStorage.setItem("auth", JSON.stringify(newAuthState));
      return newAuthState;

    case AuthActionType.LOGOUT_SUCCESS:
      localStorage.removeItem("auth");
      localStorage.removeItem("channel_id");

      return authState;

    case AuthActionType.LOGIN_SUCCESS:
      const loginAuthState = {
        isLoggedIn: true,
        user: action.payload,
      };
      axios.defaults.headers.common["Authorization"] = `Bearer ${action.payload.token}`;
      localStorage.setItem("auth", JSON.stringify(loginAuthState));
      return loginAuthState;

    default:
      return state;
  }
};

export default authreducer;
