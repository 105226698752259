import Shop from "examples/Icons/Shop";
import SpaceShip from "examples/Icons/SpaceShip";
import ForgotPassword from "layouts/auth/forgot-password";
import ResetPassword from "layouts/auth/reset-password";
import LogIn from "layouts/auth/login";
import Content from "layouts/dashboard/content";
import Dashboard from "layouts/dashboard";
import Channels from "layouts/channels";
import users from "layouts/users";
import category from "layouts/dashboard/category";
import series from "layouts/dashboard/series";
import season from "layouts/dashboard/season";
import episode from "layouts/dashboard/episode";
import ads from "layouts/dashboard/ads";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: "home",
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Content",
    key: "content",
    route: "/content",
    icon: "video_library",
    component: Content,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Category",
    key: "category",
    route: "/category",
    icon: "video_library",
    component: category,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Series",
    key: "series",
    route: "/series",
    icon: "video_library",
    component: series,
    noCollapse: true,
  },
  {
    type: "title",
    name: "Series",
    key: "series",
    route: "/series/:series_id/:series_path",
    icon: "video_library",
    component: season,
    noCollapse: true,
    showInSideBar: false,
  },
  {
    type: "title",
    name: "Series",
    key: "series",
    route: "/series/:series_id/:series_path/:season_id/:season_path",
    icon: "video_library",
    component: episode,
    noCollapse: true,
    showInSideBar: false,
  },
  {
    type: "collapse",
    name: "Ad",
    key: "ads",
    route: "/ads",
    icon: "video_library",
    component: ads,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Login",
    key: "login",
    route: "/",
    icon: <SpaceShip size="12px" />,
    component: LogIn,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/dashboard",
  },
  {
    type: "collapse",
    name: "Forgot Password",
    key: "forgot-password",
    route: "/auth/forgot-password",
    icon: <Shop size="12px" />,
    component: ForgotPassword,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/dashboard",
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    route: "/auth/reset-password",
    icon: <Shop size="12px" />,
    component: ResetPassword,
    noCollapse: true,
    inSideNav: false,
    preAuth: true,
    redirectTo: "/dashboard",
  },
  {
    type: "collapse",
    name: "brands",
    key: "brands",
    route: "/brands",
    icon: "video_library",
    component: Channels,
    noCollapse: true,
    inSideNav: false,
  },
  {
    type: "collapse",
    name: "users",
    key: "users",
    route: "/users",
    icon: "video_library",
    component: users,
    noCollapse: true,
    inSideNav: false,
  },
];

export default routes;
