import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiModal from "components/SuiModal";
import { useFormState } from "helpers/hooks/form.hook";
import { Grid } from "@mui/material";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SeriesCard from "examples/Cards/ProjectCards/SeriesCard";
import Footer from "examples/Footer";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
// Custom styles for the Tables
import "./index.scss";
import {
  createSeason,
  getSeason,
  setSeason,
  deleteSeason,
  updateSeason,
} from "redux/actions/season.action";
import { getCategory } from "redux/actions/category.action";

function SeasonComponent(props) {
  const history = useHistory();
  const params = useParams();
  const { season } = props;
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [formState, handleInputChange, resetFormState] = useFormState({});
  const [selectedSeason, setSelectedSeason] = useState({});

  const handleClose = (func) => () => {
    func(false);
    setSelectedSeason({});
    resetFormState();
  };

  const handleShow = (func) => () => {
    func(true);
  };

  useEffect(() => {
    props.get(params?.series_id);
  }, []);

  useEffect(() => {
    resetFormState(selectedSeason);
  }, [selectedSeason]);

  const handleDelete = () => {
    props.delete(params?.series_id, selectedSeason._id).then(() => {
      handleClose(setShowDelete)();
      resetFormState();
      props.get();
    });
  };

  const renderChannelCard = () => {
    if (season?.length < 1) {
      return (
        <Grid xs={12} md={6} xl={3} style={{ padding: "30px" }}>
          No Season Found !!
        </Grid>
      );
    }
    return (season || []).map((item, index) => (
      <Grid xs={12} md={6} xl={3} mb={3} style={{ padding: "20px" }} key={item._id}>
        <SeriesCard
          image={item.thumbnail}
          title={item.title}
          description={item?.description}
          onDeleteClick={() => {
            setSelectedSeason(item);
            setShowDelete(true);
          }}
          onEditClick={() => {
            setSelectedSeason(item);
            resetFormState(item);
            setShow(true);
          }}
          action={{
            color: "info",
            label: "view ",
            onClick: () => {
              history.push(
                `/series/${params?.series_id}/${params?.series_path}/${item._id}/${item.title}`
              );
            },
          }}
        />
      </Grid>
    ));
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">
                Series <SuiTypography variant="span">&gt;</SuiTypography> {params.series_path}
              </SuiTypography>
              <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShow)}>
                Add Season
              </SuiButton>
            </SuiBox>
            <SuiBox p={2}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={9} xl={3}>
                  <PlaceholderCard
                    title={{ variant: "h5", text: "New Series" }}
                    outlined
                    // onClick={handleShow(setShowModal)}
                  />
                </Grid> */}
                {renderChannelCard()}
              </Grid>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>

      <>
        <SuiModal
          header={`${selectedSeason?._id ? "Update" : "Create"} Series`}
          open={show}
          dismissible={true}
          sx={{
            minWidth: "40rem !important",
            width: "40rem",
          }}
          closeModal={handleClose(setShow)}
        >
          <SuiBox as="form" display="flex" flexDirection="column" flex={1}>
            <SuiBox mb={2}>
              <SuiInput
                label="Title"
                name="title"
                value={formState.title || ""}
                placeholder="Enter title"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Description"
                name="description"
                value={formState.description || ""}
                placeholder="Enter description"
                onChange={handleInputChange}
              />
            </SuiBox>
            <SuiBox mb={2}>
              <SuiInput
                label="Position"
                name="position"
                value={formState.position || ""}
                placeholder="Enter Position"
                onChange={handleInputChange}
              />
            </SuiBox>
            {/* <SuiBox mb={2}>
              <FileUpload
                name="thumbnail"
                label="Series Thumbnail"
                url={formState.thumbnail}
                onChange={handleInputChange}
              ></FileUpload>
            </SuiBox> */}

            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="button"
                  buttonColor="black"
                  onClick={(e) => {
                    e.preventDefault();
                    const funcToExec = selectedSeason?._id ? props.update : props.create;
                    funcToExec({ ...formState, series_id: params?.series_id }).then(() => {
                      handleClose(setShow);
                      resetFormState();
                    });
                  }}
                >
                  {selectedSeason?._id ? "Update" : "Create"}
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShow)}>
                  Cancel
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <>
        <SuiModal header="Delete Season" open={showDelete} closeModal={handleClose(setShowDelete)}>
          <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2">
                Are you sure, you want to delete <strong>{selectedSeason.name}</strong> ?
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton
                  type="submit"
                  // variant="gradient"
                  buttonColor="black"
                  onClick={handleDelete}
                >
                  Yes
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton
                  type="reset"
                  // variant="contained"
                  buttonColor="black"
                  onClick={handleClose(setShowDelete)}
                >
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>

      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    season: state?.season?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createSeason(payload)),
    get: (id) => dispatch(getSeason(id)),
    getUsers: () => dispatch(getUser()),
    delete: (series_id, season_id) => dispatch(deleteSeason(series_id, season_id)),
    update: (payload) => dispatch(updateSeason(payload)),
    set: (payload) => dispatch(setSeason(payload)),
    getCategory: () => dispatch(getCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeasonComponent);
