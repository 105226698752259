import { AdActionTypes } from "redux/actions/ad.action";

const seriesState = {
  current: {},
  list: [],
};

const adReducer = (state = seriesState, action) => {
  switch (action.type) {
    case AdActionTypes.GET_AD: {
      return { ...seriesState, list: [...action.payload.data] };
    }
    case AdActionTypes.LOGIN_SUCCESS: {
      return seriesState;
    }
    default:
      return state;
  }
};

export default adReducer;
