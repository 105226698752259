// export const config = {
//   bucketName: "littvnetwork-content",
//   secretAccessKey: "5DNfe8R+Qli76lIXzARzylPwKsYTj62gh5tSdam9",
//   accessKeyId: "AKIAXVYJ3LAUP766OZT6",
//   region: "us-east-1",
// };

export const config = {
  bucketName: "biddobucket",
  secretAccessKey: "TkveHLBnsVpW9nivHy6c7BmmhK96gPqUyI8WQ8QPhbg",
  accessKeyId: "DO00LVU6YYPKKZPDCU4L",
  region: "nyc3",
  bucket_url: "https://biddobucket.nyc3.digitaloceanspaces.com",
  end_point: "nyc3.digitaloceanspaces.com",
};
