// import { useState } from "react";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
// import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";

// Authentication layout components
import BasicLayout from "layouts/auth/components/BasicLayout";

// Images
import curved6 from "assets/images/curved-images/curved14.jpg";

function ForgotPassword() {
  // const [agreement, setAgremment] = useState(true);

  // const handleSetAgremment = () => setAgremment(!agreement);

  return (
    <BasicLayout
      title="Enter Your Email"
      description="Verification code will send on the enter email"
      image={curved6}
    >
      <Card>
        <SuiBox p={1} mb={1} textAlign="center" />

        <SuiBox pt={2} pb={3} px={3}>
          <SuiBox component="form" role="form">
            <SuiBox mb={2}>
              <SuiInput type="email" placeholder="Email" />
            </SuiBox>
            
            <SuiBox mt={4} mb={1}>
              <SuiButton variant="gradient" buttonColor="dark" fullWidth>
                Send code
              </SuiButton>
            </SuiBox>
            {/* <SuiBox mt={3} textAlign="center">
              <SuiTypography variant="button" textColor="text" fontWeight="regular">
                Send OTP?&nbsp;
                <SuiTypography
                  component={Link}
                  to="/auth/forgot-password"
                  variant="button"
                  textColor="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Reset Password
                </SuiTypography>
              </SuiTypography>
            </SuiBox> */}
          </SuiBox>
        </SuiBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;
