import Card from "@mui/material/Card";
import FileUpload from "components/FileUpload";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiModal from "components/SuiModal";
import SuiSelect from "components/SuiSelect";
import SuiToggle from "components/SuiToggle";
import SuiTypography from "components/SuiTypography";
import Footer from "examples/Footer";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Table";
import { useFormState } from "helpers/hooks/form.hook";
import videoContent from "layouts/tables/data/videoContent";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { getCategory } from "redux/actions/category.action";
import {
  createContent,
  deleteContent,
  getContent,
  updateContent,
} from "redux/actions/content.action";
import CheckIcon from "@mui/icons-material/Check";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

function Tables(props) {
  const history = useHistory();
  const { channel, contents } = props;
  const classes = styles();
  const { columns, rows } = videoContent;

  const [selectedContent, setSelectedContent] = useState({});

  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleClose = (func) => () => {
    func(false);
    setSelectedContent({});
    resetFormState();
  };
  const handleShow = (func) => () => {
    func(true);
  };
  const [formState, handleInputChange, resetFormState] = useFormState({});

  useEffect(() => {
    if (!channel?._id) {
      history.push("/brands");
    }
    Promise.all([props.get(channel?._id), props.getCat()]);
  }, []);

  const handleDelete = () => {
    props.delete(selectedContent._id).then(() => {
      handleClose(setShowDelete)();
      props.get(channel?._id);
    });
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Content</SuiTypography>
              <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShow)}>
                Add Content
              </SuiButton>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table
                columns={columns}
                rows={rows(
                  contents || [],
                  (contentData) => () => {
                    handleInputChange(contentData);
                    setSelectedContent(contentData);
                    handleShow(setShow)();
                  },
                  (deleteContentData) => () => {
                    setSelectedContent(deleteContentData);
                    handleShow(setShowDelete)();
                  }
                )}
              />
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>

      <>
        <SuiModal
          header={`${selectedContent._id ? "Update" : "Create"} Content`}
          open={show}
          dismissible={true}
          closeModal={handleClose(setShow)}
        >
          <SuiBox display="flex" flexDirection="column" flex={1}>
            <form
              onSubmit={(evnt) => {
                evnt.preventDefault();
                let funcToExec = props.create;
                if (selectedContent._id) {
                  funcToExec = props.update;
                }
                funcToExec({ ...formState, channel_id: channel?._id })
                  .then(() => {
                    handleClose(setShow)();
                  })
                  .catch((err) => {});
              }}
            >
              <SuiBox display="flex">
                <SuiBox flex={1} p={1}>
                  <FileUpload
                    name="video_url"
                    label="Video"
                    url={formState.video_url}
                    onChange={handleInputChange}
                  ></FileUpload>
                </SuiBox>

                <SuiBox flex={1} p={1} style={{ width: "33%" }}>
                  <FileUpload
                    name="thumbnail"
                    label="Thumbnail"
                    url={formState.thumbnail}
                    onChange={handleInputChange}
                  ></FileUpload>
                </SuiBox>

                <SuiBox flex={1} p={1} style={{ width: "33%" }}>
                  <FileUpload
                    name="background_image"
                    label="Bankground Image"
                    url={formState.background_image}
                    onChange={handleInputChange}
                  ></FileUpload>
                </SuiBox>
              </SuiBox>
              <SuiBox pl={1} pr={1}>
                <SuiBox mb={2}>
                  <SuiInput
                    label="Title"
                    placeholder="Enter content title"
                    name="title"
                    value={formState?.title || ""}
                    onChange={handleInputChange}
                  />
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiInput
                    label="Description"
                    name="description"
                    value={formState?.description || ""}
                    placeholder="Enter description"
                    onChange={handleInputChange}
                  />
                </SuiBox>
                <SuiBox mb={2}>
                  <SuiSelect
                    label="Category (s)"
                    multi={true}
                    name="category"
                    placeholder="Select users"
                    onChange={handleInputChange}
                    value={formState?.category || []}
                    options={props.categories}
                    renderVal={(selected) =>
                      `${selected.length} Categor${selected.length === 1 ? "y" : "ies"}`
                    }
                  >
                    {({ val }) =>
                      (props.categories || []).map((cat) => (
                        <MenuItem key={cat._id} value={cat._id}>
                          <ListItemText fontSize="small" secondary={`${cat.name}`} />
                          <ListItemIcon>
                            {val.indexOf(cat._id) === -1 ? null : (
                              <CheckIcon fontSize="medium" color="success"></CheckIcon>
                            )}
                          </ListItemIcon>
                        </MenuItem>
                      ))
                    }
                  </SuiSelect>
                </SuiBox>

                <SuiBox mb={2} display="flex" alignItems="center">
                  <SuiBox flex={4} mr={2}>
                    <SuiSelect
                      label="Status"
                      onChange={handleInputChange}
                      name="status"
                      value={formState.status}
                      options={[
                        { value: "active", label: "Active" },
                        { value: "inactive", label: "Inactive" },
                      ]}
                    />
                  </SuiBox>
                  <SuiBox flex={4} mr={2}>
                    <SuiInput
                      label="Duration (In Seconds)"
                      name="duration"
                      type="number"
                      value={formState.duration}
                      placeholder="Enter duration in seconds"
                      onChange={handleInputChange}
                    />
                  </SuiBox>
                  {/* <SuiBox flex={2}>
                    <SuiToggle
                      label="Is Premium"
                      name="is_premium"
                      value={formState.is_premium}
                      onChange={handleInputChange}
                    />
                  </SuiBox> */}
                </SuiBox>
              </SuiBox>
              <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
                <SuiBox>
                  <SuiButton type="submit" buttonColor="black">
                    {selectedContent._id ? "Update" : "Create"}
                  </SuiButton>
                </SuiBox>
                <SuiBox ml={1}>
                  <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShow)}>
                    Cancel
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </form>
          </SuiBox>
        </SuiModal>
      </>

      <>
        <SuiModal header="Delete Content" open={showDelete} closeModal={handleClose(setShowDelete)}>
          <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2">
                Are you sure, you want to delete <strong>{selectedContent.title}</strong> ?
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton type="submit" buttonColor="black" onClick={handleDelete}>
                  Yes
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShowDelete)}>
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  return {
    contents: state.content.list,
    categories: state.category.list,
    channel: state.channel.current,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createContent(payload)),
    get: (id) => dispatch(getContent(id)),
    getCat: () => dispatch(getCategory()),
    delete: (id) => dispatch(deleteContent(id)),
    update: (payload) => dispatch(updateContent(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tables);
