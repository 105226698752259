import axios from "axios";
import { AuthActionType } from "redux/actions/auth.action";
import { ContentActionTypes } from "redux/actions/content.action";

const contentState = {
  current: {},
  list: [],
};

const contentReducer = (state = contentState, action) => {
  switch (action.type) {
    case ContentActionTypes.GET_CONTENT: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return contentState;
    }
    default:
      return state;
  }
};

export default contentReducer;
