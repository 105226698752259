import axios from "axios";
import { config } from "aws.config";
import S3 from "utils/s3fileUpload";

class UploadMedia {
  constructor(handleProgress, handleSuccess, handleError) {
    this.cancelToken = axios.CancelToken.source();
    this.handleProgress = handleProgress;
    this.handleSuccess = handleSuccess;
    this.handleError = handleError;
    this.s3Upload = (file) => {
      const blob = file;
      const params = { Body: blob, Bucket: `${config.bucketName}`, Key: blob.name };
      S3.putObject(params)
        .on("build", (request) => {
          request.httpRequest.headers.Host = `${config.bucket_url}`;
          request.httpRequest.headers["Content-Length"] = blob.size;
          request.httpRequest.headers["Content-Type"] = blob.type;
          request.httpRequest.headers["x-amz-acl"] = "public-read";
        })
        .on("httpUploadProgress", (data) => {
          const progress = (data.loaded / data.total) * 100;
          if (this.handleProgress) {
            this.handleProgress(progress);
          }
        })
        .send((err) => {
          if (err && this.handleError) this.handleError(err);
          else if (this.handleSuccess) {
            const location = `${config.bucket_url}/${blob.name}`;
            this.handleSuccess(location, blob.name);
            return location;
          }
        });
    };
  }

  setFile(file) {
    if (!file) throw new Error("Invalid file");
    const ext = file.name.split(".").pop();
    this.file = new File([file], `${Date.now()}.${ext}`);
    return this;
  }

  async upload() {
    try {
      return await this.s3Upload(this.file);
    } catch (err) {
      throw err;
    }
  }

  cancel() {
    this.cancelToken.cancel();
    return this;
  }
}

export default UploadMedia;
