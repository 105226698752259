// Soft UI Dashboard React components
import { Icon } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

export default {
  columns: [
    { name: "Category_id", align: "center" },
    { name: "Category", align: "center" },
    { name: "position", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: (list, onEdit, onDelete) =>
    list.map((item) => ({
      " ": <SuiAvatar src={item.avatar} alt={item.firstname} size="sm" variant="rounded" />,
      Category_id: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item._id || "-"}
        </SuiTypography>
      ),
      Category: (
        <SuiTypography variant="button" fontWeight="medium">
          {item.name || "-"}
        </SuiTypography>
      ),
      position: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.position || "-"}
        </SuiTypography>
      ),
      Role: (
        <SuiBadge
          variant="gradient"
          badgeContent={item?.role}
          color={item?.role === "user" ? "success" : "error"}
          size="extra-small"
        />
      ),

      email: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item?.email || ""}
        </SuiTypography>
      ),
      updated_at: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.updatedAt ? new Date(item.updatedAt).toLocaleString() : ""}
        </SuiTypography>
      ),
      created_at: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.createdAt ? new Date(item.createdAt).toLocaleString() : ""}
        </SuiTypography>
      ),
      action: (
        <SuiBox>
          <SuiButton variant="text" buttonColor="dark" onClick={onEdit(item)}>
            <Icon className="material-icons-round">edit</Icon>&nbsp;edit
          </SuiButton>
          <SuiButton variant="text" buttonColor="dark" onClick={onDelete(item)}>
            <Icon className="material-icons-round">delete</Icon>&nbsp;delete
          </SuiButton>
        </SuiBox>
      ),
    })),
};
