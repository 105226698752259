import axios from "axios";
import { AuthActionType } from "redux/actions/auth.action";
import { ContentActionTypes } from "redux/actions/live_stream.action";

const liveStreamState = {
  current: {},
  list: [],
};

const liveStreamReducer = (state = liveStreamState, action) => {
  switch (action.type) {
    case ContentActionTypes.GET_LIVE_STREAM_CONTENT: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return liveStreamState;
    }

    default:
      return state;
  }
};

export default liveStreamReducer;
