// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
function Footer({ company, links }) {
  

  return (
    <SuiBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      
    </SuiBox>
  );
}

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
