// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select_test123__2V009 #select_demo-simple-select__2fuCX {\n  flex: 1 1; }\n", "",{"version":3,"sources":["webpack://src/components/SuiSelect/select.module.scss"],"names":[],"mappings":"AAAA;EAGQ,SAAO,EAAA","sourcesContent":[".test123 {\n\n    #demo-simple-select {\n        flex: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"test123": "select_test123__2V009",
	"demo-simple-select": "select_demo-simple-select__2fuCX"
};
export default ___CSS_LOADER_EXPORT___;
