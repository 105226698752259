import { AuthActionType } from "redux/actions/auth.action";
import { SeriesActionTypes } from "redux/actions/series.action";

const seriesState = {
  current: {},
  list: [],
};

const seriesReducer = (state = seriesState, action) => {
  switch (action.type) {
    case SeriesActionTypes.GET_SERIES: {
      return { ...seriesState, list: [...action.payload.data] };
    }
    // case SeriesActionTypes.SET_SERIES: {
    //   return { ...seriesState, list: [...action.payload] };
    // }

    case AuthActionType.LOGIN_SUCCESS: {
      return seriesState;
    }

    default:
      return state;
  }
};

export default seriesReducer;
