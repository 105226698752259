import { series } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";
import { AuthActionType } from "./auth.action";

export const SeriesActionTypes = {
  CREATE_SERIES: "CREATE_SERIES",
  DELETE_SERIES: "DELETE_SERIES",
  UPDATE_SERIES: "UPDATE_SERIES",
  GET_SERIES: "GET_SERIES",
  SET_SERIES: "SET_SERIES",
};

export const getSeries = (id) => {
  return (dispatch) =>
    axios
      .get(`${series}/brand/${id}`)
      .then((res) => {
        const { data } = res;
        dispatch({ type: SeriesActionTypes.GET_SERIES, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Series Error..").error(_err);

        // on 403 remove auth token
        if (_err && err?.response?.status == 403) {
          dispatch({ type: AuthActionType.LOGOUT_SUCCESS });
        }

        throw err;
      });
};

export const createSeries = (payload) => {
  return (dispatch) =>
    axios
      .post(series, payload)
      .then((res) => getSeries()(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Series Error..").error(_err);

        throw err;
      });
};

export const updateSeries = (payload) => {
  return (dispatch) =>
    axios
      .put(`${series}/${payload._id}`, payload)
      .then((res) => {
        getSeries()(dispatch).then(() => {
          dispatch({ type: SeriesActionTypes.SET_SERIES, payload: payload._id });
        });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Series Error..").error(_err);
        throw err;
      });
};

export const deleteSeries = (id) => {
  return (dispatch) =>
    axios
      .delete(`${series}/${id}`)
      .then((res) => {
        getSeries()(dispatch);
        dispatch({ type: SeriesActionTypes.SET_SERIES, payload: null });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Series Error..").error(_err);
        throw err;
      });
};

export const setSeries = (id) => (dispatch) => {
  dispatch({ type: SeriesActionTypes.SET_SERIES, payload: id });
};
