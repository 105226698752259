// export const base_url = "http://localhost:8080";
// export const base_url = "https://biddo-api-8wnen.ondigitalocean.app";
export const base_url = "https://api.biddo.net";

export const login = "/auth/login";

export const dashboard = "/dashboard";

export const content = "/content";

export const liveContent = "/live-content";

export const scheduledContent = "/scheduled-content";

export const channel = "/brand";

export const uploadContentURL = "/upload-content";

export const playlist = "/playlist";

export const user = "/user";

export const category = "/category";

export const series = "/series";

export const ad = "/ad";
