import { Card, Grid } from "@mui/material";
import Logo from "assets/images/logo.jpg";
import FileUpload from "components/FileUpload";
import SuiAvatar from "components/SuiAvatar";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiLogout from "components/SuiLogout";
import SuiModal from "components/SuiModal";
import SuiSelect from "components/SuiSelect";
import SuiTypography from "components/SuiTypography";
import { useSoftUIController } from "context";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import Table from "examples/Table";
import { useFormState } from "helpers/hooks/form.hook";
import styles from "layouts/channels/style";
import userContent from "layouts/tables/data/users_content";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { createUser, deleteUser, getUser, updateUser } from "redux/actions/users.action";

function Users(props) {
  const { user } = props;

  const { columns, rows } = userContent;

  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (user.role === "admin") {
      props.get();
    } else {
      history.push("/brands");
    }
  }, []);

  useEffect(() => {
    dispatch({ type: "LAYOUT", value: "page" });
  }, [pathname]);

  const classes = styles();

  const [formState, handleInputChange, resetFormState] = useFormState({});

  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState({});

  const [showDelete, setShowDelete] = useState(false);

  const handleClose = (func) => () => {
    func(false);
    setSelectedUser({});
    resetFormState();
  };
  const handleShow = (func) => () => {
    func(true);
  };

  const handleDelete = () => {
    props.delete(selectedUser._id).then(() => {
      handleClose(setShowDelete)();
      props.get();
    });
  };

  return (
    <>
      <SuiBox px={4} py={4}>
        <div className="channels-list">
          <SuiBox position="relative" pb={3}>
            {/* <SuiBox customClass={classes.profileHeader_background} /> */}
            <Card className={classes.profileHeader_profile}>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <SuiAvatar
                    src={Logo}
                    alt="LitTV Network"
                    // variant="rounded"
                    size="lg"
                    // customClass="shadow-sm"
                  />
                </Grid>
                <Grid item>
                  <SuiBox height="100%" mt={0.5} lineHeight={1}>
                    <SuiTypography variant="h5" fontWeight="medium">
                      {user?.firstname || ""} {user?.lastname || ""}
                    </SuiTypography>
                    <SuiTypography variant="button" textColor="text" fontWeight="medium">
                      <span style={{ textTransform: "uppercase" }}>{user?.role}</span> /{" "}
                      <span>{user?.email}</span>
                    </SuiTypography>
                  </SuiBox>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className="m-auto " display={"flex"}>
                  <Link
                    to={"/brands"}
                    key={"sss"}

                    // className={classes.sidenav_navlink}
                  >
                    <SidenavCollapse
                      name={"Brands"}
                      icon={"splitscreen"}
                      active={true}
                      // noCollapse={noCollapse}
                    />
                  </Link>
                  <Link
                    to={"/users"}
                    key={"sss"}
                    // className={classes.sidenav_navlink}
                  >
                    <SidenavCollapse
                      name={"Users"}
                      icon={"group"}
                      active={true}
                      // noCollapse={noCollapse}
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className="ml-auto">
                  <SuiLogout />
                </Grid>
              </Grid>
            </Card>
          </SuiBox>

          <SuiBox mb={3}>
            <Card>
              <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                <SuiTypography variant="h6">Users</SuiTypography>
                <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShowModal)}>
                  Add User
                </SuiButton>
              </SuiBox>
              <SuiBox customClass={classes.tables_table}>
                <Table
                  columns={columns}
                  rows={rows(
                    props.users || [],
                    (userData) => () => {
                      handleInputChange({
                        ...userData,
                      });
                      setSelectedUser(userData);
                      handleShow(setShowModal)();
                    },
                    (deleteContentData) => () => {
                      setSelectedUser(deleteContentData);
                      handleShow(setShowDelete)();
                    }
                  )}
                />
              </SuiBox>
            </Card>
          </SuiBox>
        </div>
      </SuiBox>
      <SuiModal
        header={`${selectedUser._id ? "Update" : "Create"} User`}
        open={showModal}
        dismissible={true}
        sx={{
          minWidth: "40rem !important",
          width: "40rem",
        }}
        closeModal={handleClose(setShowModal)}
      >
        <SuiBox
          as="form"
          display="flex"
          flexDirection="column"
          flex={1}
          onSubmit={(e) => {
            e.preventDefault();
            const funcToExec = selectedUser._id ? props.update : props.create;
            funcToExec(formState).then(() => {
              handleClose(setShowModal)();
            });
          }}
        >
          <SuiBox mb={2}>
            <SuiInput
              label="First Name"
              name="firstname"
              value={formState.firstname || ""}
              placeholder="Enter first name"
              onChange={handleInputChange}
            />
            <SuiInput
              label="Last Name"
              name="lastname"
              value={formState.lastname || ""}
              placeholder="Enter last name"
              onChange={handleInputChange}
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiSelect
              label="Role"
              onChange={handleInputChange}
              name="role"
              value={formState.role
                ?.split("_")
                ?.join(" ")
                ?.replace(/(^\w|\s\w)(\S*)/g, (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase())}
              options={[
                { value: "admin", label: "Admin" },
                { value: "brand_user", label: "Brand User" },
              ]}
            />
          </SuiBox>
          <SuiBox mb={2}>
            <SuiInput
              label="Email Address"
              name="email"
              type="email"
              value={formState.email || ""}
              placeholder="Enter user email address"
              onChange={handleInputChange}
              disabled={!!selectedUser._id}
            />
          </SuiBox>
          {!selectedUser._id && (
            <SuiBox mb={2}>
              <SuiInput
                label="Password"
                name="password"
                type="password"
                value={formState.password || ""}
                placeholder="Enter user password"
                onChange={handleInputChange}
              />
              <SuiInput
                label="Confirm Password"
                name="confirm_password"
                type="password"
                value={formState.confirm_password || ""}
                placeholder="Confirm password"
                onChange={handleInputChange}
              />
            </SuiBox>
          )}

          <SuiBox mb={2}>
            <FileUpload
              name="avatar"
              label="User Avatar"
              url={formState.avatar}
              onChange={handleInputChange}
            ></FileUpload>
          </SuiBox>

          <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
            <SuiBox>
              <SuiButton type="submit" buttonColor="black">
                {selectedUser._id ? "Update" : "Create"}
              </SuiButton>
            </SuiBox>
            <SuiBox ml={1}>
              <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShowModal)}>
                Cancel
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiModal>

      <SuiModal header="Delete Content" open={showDelete} closeModal={handleClose(setShowDelete)}>
        <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
          <SuiBox display="flex" alignItems="center">
            <SuiTypography variant="body2" textColor="light">
              Are you sure, you want to delete{" "}
              <strong>
                {selectedUser.firstname} {selectedUser.lastname}
              </strong>{" "}
              ?
            </SuiTypography>
          </SuiBox>
          <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
            <SuiBox>
              <SuiButton type="submit" buttonColor="black" onClick={handleDelete}>
                Yes
              </SuiButton>
            </SuiBox>
            <SuiBox ml={1}>
              <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShowDelete)}>
                No
              </SuiButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>
      </SuiModal>
    </>
  );
}

Users.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state?.authState?.user || {},
    channel: state?.channel?.list || [],
    users: state?.users?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createUser(payload)),
    get: () => dispatch(getUser()),
    delete: (id) => dispatch(deleteUser(id)),
    update: (payload) => dispatch(updateUser(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
