import { user } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const UserActionTypes = {
  CREATE_USER: "CREATE_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER: "UPDATE_USER",
  GET_USER: "GET_USER",
};

export const getUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: UserActionTypes.GET_USER, payload: [] });
    return axios.get(user).then((res) => {
      const { data } = res;
      dispatch({ type: UserActionTypes.GET_USER, payload: data });
      return res;
    });
  };
};

export const createUser = (payload) => {
  return (dispatch, getState) => {
    return axios
      .post(user, { ...payload })
      .then((res) => getUser()(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("User Error..").error(_err);
        throw err;
      });
  };
};

export const updateUser = (payload) => {
  return (dispatch) =>
    axios
      .put(`${user}/${payload._id}`, payload)
      .then((res) => getUser()(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("User Error..").error(_err);
        throw err;
      });
};

export const deleteUser = (id) => {
  return (dispatch) =>
    axios
      .delete(`${user}/${id}`)
      .then((res) => getUser()(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("User Error..").error(_err);
        throw err;
      });
};
