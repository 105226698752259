// @mui material components
import Grid from "@mui/material/Grid";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

function Footer() {
  return (
    <SuiBox component="footer" py={6}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={8} className="text-center">
          {/* <SuiTypography variant="body2" textColor="secondary">
            Copyright &copy; 2024 by Biddo.
          </SuiTypography> */}
        </Grid>
      </Grid>
    </SuiBox>
  );
}

export default Footer;
