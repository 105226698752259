// Soft UI Dashboard React components
import { Icon } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";


// // { image, name, description }
// function Title({ image, name, description }) {
//   return (
//     <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
//       <SuiBox mr={2}>
//         <SuiAvatar src={image} alt={name} size="sm" variant="rounded" />
//       </SuiBox>
//       <SuiBox display="flex" flexDirection="column">
//         <SuiTypography variant="button" fontWeight="medium">
//           {name}
//         </SuiTypography>
//         <SuiBox sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "10rem" }}>
//           <SuiTypography variant="caption" fontWeight="secondary">
//             {description}
//           </SuiTypography>
//         </SuiBox>
//       </SuiBox>
//     </SuiBox>
//   );
// }


export default {
  columns: [
    { name: " ", align: "center" },
    { name: "firstname", align: "center" },
    { name: "lastname", align: "center" },
    { name: "Role", align: "cente" },
    { name: "email", align: "center" },
    { name: "updated_at", align: "center" },
    { name: "created_at", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: (list, onEdit, onDelete) =>
    list.map((item) => ({
      " ":  <SuiAvatar src={item.avatar} alt={item.firstname} size="sm" variant="rounded" />,
      firstname: (
        <SuiTypography variant="button" fontWeight="medium">
          {item.firstname || '-'}
        </SuiTypography>
      ),
      lastname: (
        <SuiTypography variant="button" fontWeight="medium">
          {item.lastname || '-'}
        </SuiTypography>
      ),
      Role: (
        <SuiBadge
          variant="gradient"
          badgeContent={item?.role}
          color={item?.role === "user" ? "success" : "error"}
          size="extra-small"
        />
      ),

      email: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item?.email || ""}
        </SuiTypography>
      ),
      updated_at: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.updatedAt ? new Date(item.updatedAt).toLocaleString() : ""}
        </SuiTypography>
      ),
      created_at: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.createdAt ? new Date(item.createdAt).toLocaleString() : ""}
        </SuiTypography>
      ),
      action: (
        <SuiBox>
          <SuiButton variant="text" buttonColor="dark" onClick={onEdit(item)}>
            <Icon className="material-icons-round">edit</Icon>&nbsp;edit
          </SuiButton>
          <SuiButton variant="text" buttonColor="dark" onClick={onDelete(item)}>
            <Icon className="material-icons-round">delete</Icon>&nbsp;delete
          </SuiButton>
        </SuiBox>
      ),
    })),
};
