import { AuthActionType } from "redux/actions/auth.action";
import { SeasonActionTypes } from "redux/actions/season.action";

const seasonState = {
  current: {},
  list: [],
};

const seasonReducer = (state = seasonState, action) => {
  switch (action.type) {
    case SeasonActionTypes.GET_SEASON: {
      return { ...seasonState, list: [...action.payload] };
    }
    case AuthActionType.LOGIN_SUCCESS: {
      return seasonState;
    }
    default:
      return state;
  }
};

export default seasonReducer;
