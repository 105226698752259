import { series } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";
import { AuthActionType } from "./auth.action";
import season from "layouts/dashboard/season";

export const SeasonActionTypes = {
  CREATE_SEASON: "CREATE_SEASON",
  DELETE_SEASON: "DELETE_SEASON",
  UPDATE_SEASON: "UPDATE_SEASON",
  GET_SEASON: "GET_SEASON",
  SET_SEASON: "SET_SEASON",
};

export const getSeason = (id) => {
  return (dispatch) =>
    axios
      .get(`${series}/${id}/season`)
      .then((res) => {
        const { data } = res;
        dispatch({ type: SeasonActionTypes.GET_SEASON, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Season Error..").error(_err);

        // on 403 remove auth token
        if (_err && err?.response?.status == 403) {
          dispatch({ type: AuthActionType.LOGOUT_SUCCESS });
        }

        throw err;
      });
};

export const createSeason = (payload) => {
  return (dispatch) =>
    axios
      .post(`series/${payload.series_id}/season`, payload)
      .then((res) => getSeason(payload.series_id)(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Season Error..").error(_err);
        throw err;
      });
};

export const updateSeason = (payload, season_id) => {
  return (dispatch) =>
    axios
      .put(`${series}/${payload.series_id}/season/${season_id}`, payload)
      .then((res) => {
        getSeason(payload.series_id)(dispatch).then(() => {
          dispatch({ type: SeasonActionTypes.SET_SEASON, payload: payload._id });
        });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Season Error..").error(_err);
        throw err;
      });
};

export const deleteSeason = (series_id, season_id) => {
  return (dispatch) =>
    axios
      .delete(`${series}/${series_id}/season/${season_id}`)
      .then((res) => {
        getSeason(series_id)(dispatch);
        dispatch({ type: SeasonActionTypes.SET_SEASON, payload: null });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Season Error..").error(_err);
        throw err;
      });
};

export const setSeason = (id) => (dispatch) => {
  dispatch({ type: SeasonActionTypes.SET_SEASON, payload: id });
};
