import { channel } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";
import {AuthActionType} from "./auth.action"

export const ChannelActionTypes = {
  CREATE_CHANNEL: "CREATE_CHANNEL",
  DELETE_CHANNEL: "DELETE_CHANNEL",
  UPDATE_CHANNEL: "UPDATE_CHANNEL",
  GET_CHANNEL: "GET_CHANNEL",
  SET_CHANNEL: "SET_CHANNEL",
};

export const getChannel = () => {
  return (dispatch) =>
    axios.get(channel)
    .then((res) => {
      const { data } = res;
      dispatch({ type: ChannelActionTypes.GET_CHANNEL, payload: data });
      return res;
    })
    .catch((err) => {
      const _err = err?.response?.data?.error_message;
      NotifierInstance.setTitle("Channel Error..").error(_err);

      // on 403 remove auth token
      if (_err && err?.response?.status == 403) {
        dispatch({ type: AuthActionType.LOGOUT_SUCCESS});
      }

      throw err;
    });
};

export const createChannel = (payload) => {
  return (dispatch) =>
    axios
      .post(channel, payload)
      .then((res) => getChannel()(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Channel Error..").error(_err);
        
        throw err;
      });
};

export const updateChannel = (payload) => {
  return (dispatch) =>
    axios
      .put(`${channel}/${payload._id}`, payload)
      .then((res) => {
        getChannel()(dispatch).then(() => {
          dispatch({ type: ChannelActionTypes.SET_CHANNEL, payload: payload._id });
        });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Channel Error..").error(_err);
        throw err;
      });
};

export const deleteChannel = (id) => {
  return (dispatch) =>
    axios
      .delete(`${channel}/${id}`)
      .then((res) => {
        getChannel()(dispatch);
        dispatch({ type: ChannelActionTypes.SET_CHANNEL, payload: null });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Channel Error..").error(_err);
        throw err;
      });
};

export const setChannel = (id) => (dispatch) => {
  dispatch({ type: ChannelActionTypes.SET_CHANNEL, payload: id });
};
