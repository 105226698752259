import { category } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const CategoryActionTypes = {
  CREATE_CATEGORY: "CREATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  GET_CATEGORY: "GET_CATEGORY",
};

export const getCategory = () => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id: channel_id } = current;

    dispatch({ type: CategoryActionTypes.GET_CATEGORY, payload: [] });
    return axios
      .get(category, {
        params: {
          channel_id,
        },
      })
      .then((res) => {
        const { data } = res;
        dispatch({ type: CategoryActionTypes.GET_CATEGORY, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Category Error..").error(_err);
        throw err;
      });
  };
};

export const createCategory = (payload) => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id: channel_id } = current;
    return axios
      .post(category, { ...payload, channel_id })
      .then((res) => getCategory()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Category Error..").error(_err);
        throw err;
      });
  };
};

export const updateCategory = (payload) => {
  return (dispatch, getState) =>
    axios
      .put(`${category}/${payload._id}`, payload)
      .then((res) => getCategory()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Category Error..").error(_err);
        throw err;
      });
};

export const deleteCategory = (id) => {
  return (dispatch, getState) =>
    axios
      .delete(`${category}/${id}`)
      .then((res) => getCategory()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Category Error..").error(_err);
        throw err;
      });
};
