import Card from "@mui/material/Card";
import FileUpload from "components/FileUpload";
// Soft UI Dashboard React components
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiModal from "components/SuiModal";
import SuiSelect from "components/SuiSelect";
import SuiToggle from "components/SuiToggle";
import SuiTypography from "components/SuiTypography";
import { useSoftUIController } from "context";
import Footer from "examples/Footer";
// Soft UI Dashboard React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Table from "examples/Table";
import { useFormState } from "helpers/hooks/form.hook";
import category_content from "layouts/tables/data/category_content";
import videoContent from "layouts/tables/data/videoContent";
// Custom styles for the Tables
import styles from "layouts/tables/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { updateCategory } from "redux/actions/category.action";
import { deleteCategory } from "redux/actions/category.action";
import { getCategory } from "redux/actions/category.action";
import { createCategory } from "redux/actions/category.action";

function Category(props) {
  const { user } = props;

  const { columns, rows } = category_content;

  const [, dispatch] = useSoftUIController();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    props.get();
  }, []);

  // useEffect(() => {
  //   dispatch({ type: "LAYOUT", value: "page" });
  // }, [pathname]);

  const classes = styles();

  const [formState, handleInputChange, resetFormState] = useFormState({});

  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState({});

  const [showDelete, setShowDelete] = useState(false);

  const handleClose = (func) => () => {
    func(false);
    setSelectedCategory({});
    resetFormState();
  };
  const handleShow = (func) => () => {
    func(true);
  };

  const handleDelete = () => {
    props.delete(selectedCategory._id).then(() => {
      handleClose(setShowDelete)();
      props.get();
    });
  };

  return (
    <DashboardLayout>
      <SuiBox py={3}>
        <SuiBox mb={3}>
          <Card>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SuiTypography variant="h6">Category</SuiTypography>
              <SuiButton variant="gradient" buttonColor="dark" onClick={handleShow(setShow)}>
                Add Category
              </SuiButton>
            </SuiBox>
            <SuiBox customClass={classes.tables_table}>
              <Table
                columns={columns}
                rows={rows(
                  props.category || [],
                  (contentData) => () => {
                    handleInputChange({
                      ...contentData,
                    });
                    setSelectedCategory(contentData);
                    handleShow(setShow)();
                  },
                  (deleteContentData) => () => {
                    setSelectedCategory(deleteContentData);
                    handleShow(setShowDelete)();
                  }
                )}
              />
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox>

      <>
        <SuiModal
          header={`${selectedCategory._id ? "Update" : "Create"} Category`}
          open={show}
          dismissible={true}
          closeModal={handleClose(setShow)}
        >
          <SuiBox display="flex" flexDirection="column" flex={1}>
            <form
              onSubmit={(evnt) => {
                evnt.preventDefault();
                let funcToExec = props.create;
                if (selectedCategory._id) {
                  funcToExec = props.update;
                }
                funcToExec(formState)
                  .then(() => {
                    handleClose(setShow)();
                  })
                  .catch((err) => {
                    alert(err);
                  });
              }}
            >
              <SuiBox flex={4} mr={2} mb={1}>
                <SuiInput
                  label="Category Name"
                  name="name"
                  value={formState?.name || ""}
                  placeholder="Enter Category"
                  onChange={handleInputChange}
                />
              </SuiBox>
              <SuiBox flex={4} mr={2}>
                <SuiInput
                  label="Position"
                  name="position"
                  type="number"
                  value={formState?.position || ""}
                  placeholder="Enter position"
                  onChange={handleInputChange}
                />
              </SuiBox>

              <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
                <SuiBox>
                  <SuiButton type="submit" buttonColor="black">
                    {selectedCategory._id ? "Update" : "Create"}
                  </SuiButton>
                </SuiBox>
                <SuiBox ml={1}>
                  <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShow)}>
                    Cancel
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </form>
          </SuiBox>
        </SuiModal>
      </>

      <>
        <SuiModal header="Delete Content" open={showDelete} closeModal={handleClose(setShowDelete)}>
          <SuiBox display="flex" flexDirection="column" alignItems="center" flex={1}>
            <SuiBox display="flex" alignItems="center">
              <SuiTypography variant="body2" textColor="light">
                Are you sure, you want to delete <strong>{selectedCategory.name}</strong> ?
              </SuiTypography>
            </SuiBox>
            <SuiBox mt={4} mb={1} justifyContent="center" alignItems="center" display="flex">
              <SuiBox>
                <SuiButton type="submit" buttonColor="black" onClick={handleDelete}>
                  Yes
                </SuiButton>
              </SuiBox>
              <SuiBox ml={1}>
                <SuiButton type="reset" buttonColor="black" onClick={handleClose(setShowDelete)}>
                  No
                </SuiButton>
              </SuiBox>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      </>
      <Footer />
    </DashboardLayout>
  );
}

Category.propTypes = {};

const mapStateToProps = (state) => {
  return {
    user: state?.authState?.user || {},
    users: state?.users?.list || [],
    channel: state?.channel?.list || [],
    category: state?.category?.list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    create: (payload) => dispatch(createCategory(payload)),
    get: () => dispatch(getCategory()),
    delete: (id) => dispatch(deleteCategory(id)),
    update: (payload) => dispatch(updateCategory(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
