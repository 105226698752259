import axios from "axios";

const { dashboard } = require("api.config");

export const DASHBOARD_ACTION_TYPES = {
  SET_META: "SET_META",
};

export const getDashboardMeta = () => {
  return async (dispatch, getState) => {
    try {
      const {channel: { current }} = getState();
      const { _id: channel_id } = current;
      
      const res = await axios.get(dashboard,{
        params: {
          channel_id,
        },
      });

      const { data } = res;
      dispatch({ type: DASHBOARD_ACTION_TYPES.SET_META, payload: data });
    } catch (error) {
    }
  };
};
