import { series } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";
import { AuthActionType } from "./auth.action";

export const EpisodeActionTypes = {
  CREATE_EPISODE: "CREATE_EPISODE",
  DELETE_EPISODE: "DELETE_EPISODE",
  UPDATE_EPISODE: "UPDATE_EPISODE",
  GET_EPISODE: "GET_EPISODE",
  SET_EPISODE: "SET_EPISODE",
};

export const getEpisode = (series_id, season_id) => {
  return (dispatch) =>
    axios
      .get(`${series}/${series_id}/season/${season_id}/episode`)
      .then((res) => {
        const { data } = res;
        dispatch({ type: EpisodeActionTypes.GET_EPISODE, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Episode Error..").error(_err);
        // on 403 remove auth token
        if (_err && err?.response?.status == 403) {
          dispatch({ type: AuthActionType.LOGOUT_SUCCESS });
        }

        throw err;
      });
};

export const createEpisode = (payload) => {
  return (dispatch) =>
    axios
      .post(`series/${payload.series_id}/season/${payload.season_id}/episode`, payload)
      .then((res) => getEpisode(payload.series_id, payload.season_id)(dispatch))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Episode Error..").error(_err);
        throw err;
      });
};

export const updateEpisode = (payload, season_id) => {
  return (dispatch) =>
    axios
      .put(`${series}/${payload.series_id}/season/${season_id}`, payload)
      .then((res) => {
        getEpisode(payload.series_id)(dispatch).then(() => {
          dispatch({ type: EpisodeActionTypes.SET_EPISODE, payload: payload._id });
        });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Episode Error..").error(_err);
        throw err;
      });
};

export const deleteEpisode = (series_id, season_id, episode_id) => {
  return (dispatch) =>
    axios
      .delete(`${series}/${series_id}/season/${season_id}/episode/${episode_id}`)
      .then((res) => {
        getEpisode(series_id, season_id)(dispatch);
        dispatch({ type: EpisodeActionTypes.SET_EPISODE, payload: null });
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Episode Error..").error(_err);
        throw err;
      });
};

export const setEpisode = (id) => (dispatch) => {
  dispatch({ type: EpisodeActionTypes.SET_EPISODE, payload: id });
};
