import { liveContent } from "api.config";
import axios from "axios";
import { NotifierInstance } from "utils/notification/notify";

export const ContentActionTypes = {
  CREATE_LIVE_STREAM_CONTENT: "CREATE_LIVE_STREAM_CONTENT",
  DELETE_LIVE_STREAM_CONTENT: "DELETE_LIVE_STREAM_CONTENT",
  UPDATE_LIVE_STREAM_CONTENT: "UPDATE_LIVE_STREAM_CONTENT",
  GET_LIVE_STREAM_CONTENT: "GET_LIVE_STREAM_CONTENT",
};

export const getContent = () => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id: channel_id } = current;

    return axios
      .get(liveContent, {
        params: {
          channel_id,
        },
      })
      .then((res) => {
        const { data } = res;
        dispatch({ type: ContentActionTypes.GET_LIVE_STREAM_CONTENT, payload: data });
        return res;
      })
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Live Stream Error..").error(_err);
        throw err;
      });
  };
};

export const createContent = (payload) => {
  return (dispatch, getState) => {
    const {
      channel: { current },
    } = getState();
    const { _id: channel_id } = current;

    return axios
      .post(liveContent, { ...payload, channel_id })
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Live Stream Error..").error(_err);
        throw err;
      });
  };
};

export const updateContent = (payload) => {
  return (dispatch, getState) =>
    axios
      .put(`${liveContent}/${payload._id}`, payload)
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Live Stream Error..").error(_err);
        throw err;
      });
};

export const deleteContent = (id) => {
  return (dispatch, getState) =>
    axios
      .delete(`${liveContent}/${id}`)
      .then((res) => getContent()(dispatch, getState))
      .catch((err) => {
        const _err = err?.response?.data?.error_message;
        NotifierInstance.setTitle("Live Stream Error..").error(_err);
        throw err;
      });
};
