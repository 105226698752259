import { AuthActionType } from "redux/actions/auth.action";
import { ContentActionTypes } from "redux/actions/scheduled_content.action";

const scheduledContentState = {
  current: {},
  list: [],
};

const scheduledContentReducer = (state = scheduledContentState, action) => {
  switch (action.type) {
    case ContentActionTypes.GET_SCHEDULED_CONTENT: {
      return { list: [...action.payload] };
    }

    case AuthActionType.LOGIN_SUCCESS: {
      return scheduledContentState;
    }

    default:
      return state;
  }
};

export default scheduledContentReducer;
