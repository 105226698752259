// Soft UI Dashboard React components
import { Badge, Icon } from "@mui/material";
import SuiAvatar from "components/SuiAvatar";
import SuiBadge from "components/SuiBadge";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";

function Title({ text }) {
  return (
    <SuiBox display="flex" alignItems="center" px={1} py={0.5}>
      <SuiBox display="flex" flexDirection="column">
        <SuiTypography variant="button" fontWeight="medium">
          {text}
        </SuiTypography>
      </SuiBox>
    </SuiBox>
  );
}

export default {
  columns: [
    { name: "urls", align: "left" },
    { name: "pre_roll", align: "center" },
    { name: "mid_roll", align: "center" },
    { name: "post_roll", align: "center" },
    { name: "positions", align: "center" },
    { name: "action", align: "center" },
  ],

  rows: (list, onEdit, onDelete) =>
    list.map((item) => ({
      urls: <Title text={item?.ad_urls?.join(",")} />,
      pre_roll: (
        <SuiBadge
          variant="gradient"
          badgeContent={item?.pre_roll ? "enable" : "disable"}
          color={item?.pre_roll ? "success" : "error"}
          size="extra-small"
        />
      ),
      mid_roll: (
        <SuiBadge
          variant="gradient"
          badgeContent={item?.mid_roll ? "enable" : "disable"}
          color={item?.mid_roll ? "success" : "error"}
          size="extra-small"
        />
      ),
      post_roll: (
        <SuiBadge
          variant="gradient"
          badgeContent={item?.post_roll ? "enable" : "disable"}
          color={item?.post_roll ? "success" : "error"}
          size="extra-small"
        />
      ),
      positions: (
        <SuiTypography variant="caption" textColor="secondary" fontWeight="medium">
          {item.ad_positions ? item?.ad_positions?.join(",") : ""}
        </SuiTypography>
      ),
      action: (
        <SuiBox>
          <SuiButton variant="text" buttonColor="dark" onClick={onEdit(item)}>
            <Icon className="material-icons-round">edit</Icon>&nbsp;edit
          </SuiButton>
          <SuiButton variant="text" buttonColor="dark" onClick={onDelete(item)}>
            <Icon className="material-icons-round">delete</Icon>&nbsp;delete
          </SuiButton>
        </SuiBox>
      ),
    })),
};
